<template>
  <div class="list" style="max-width: 100%!important;">
    <b-alert v-model="error" variant="danger" dismissible>
      {{ errorMsg }}
    </b-alert>
    <b-table class="col-12" striped hover :items="boxTypes" :fields="fields">
      <template #cell(#)="row">
        {{ row.index }}
      </template>
      <template #cell(description)="row">
        <b-form-textarea
            id="description"
            v-model="row.item.description"
            rows="3"
            max-rows="6"
            disabled
        ></b-form-textarea>
      </template>
      <template #cell(Edit)="row">
        <router-link class="btn btn-primary" :to="{ name: 'EditBoxType', params: { id: row.item.id} }">Edit</router-link>
      </template>
      <template #cell(Delete)="row">
        <button @click="deleteSetTopBoxType(row.item.id)" class="btn btn-danger">Delete</button>
      </template>
    </b-table>
  </div>
</template>

<script>
import SetTopBoxType from "../services/SetTopBoxTypeDataService";
import SetTopBoxTypeDataService from "../services/SetTopBoxTypeDataService";

export default {
  name: "setTopBoxTypes-list",
  data() {
    return {
      fields: [
        '#',
        {
          key: 'name',
          sortable: true,
        }, {
          key: 'description',
        },
        {
          key:  'Edit',
        },
        {
          key:  'Delete',
        },
      ],
      boxTypes: [],
      name: "",
      error: false,
      errorMsg: '',
    };
  },
  methods: {
    retrieveSetTopBoxTypes() {
      SetTopBoxType.getAll()
          .then(response => {
            this.boxTypes = response.data;
            console.log(response.data);
          })
          .catch(e => {
            console.log(e);
          });
    },

    refreshList() {
      this.retrieveSetTopBoxTypes();
    },

    deleteSetTopBoxType(id) {
      SetTopBoxTypeDataService.delete(id)
          .then(response => {
            console.log(response.data);
            this.error = response.data.error;
            this.errorMsg = response.data.message;
            //setTimeout(() => this.error = false, 5000);
            if(!response.data.error){
              this.refreshList();
            }
          })
          .catch(e => {
            console.log(e);
          });
    }

  },
  mounted() {
    this.retrieveSetTopBoxTypes();
  }
};
</script>

<style>
.list {
  text-align: left;
  max-width: 750px;
  margin: auto;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
